/* eslint-disable */
import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';

export default class JsonField extends GenericField {
  constructor(name, label, keys = []) {
    super(name, label);
    this.keys = keys;
  }

  forPresenter(value) {
    return value;
  }

  forFormInitialValue(value) {
    if (value) return value;
    const val = {}

    if (this.keys.length) {
      this.keys.forEach(key => {
        val[key] = ''
      });
    }

    return val
  }
  
  forFilterInitialValue(value) {
    return value;
  }

  forFilterRules() {
    return undefined;
  }

  forFormRules() {
    let yupChain = yup.mixed().label(this.label);
    return yupChain;
  }

  forFormCast() {
    let yupChain = yup
    .object()
    .nullable(true)
    .label(this.label);

    return yupChain;
  }

  forFilterCast() {
    let yupChain = yup
    .object()
    .nullable(true)
    .label(this.label);

    return yupChain;
  }
  
  forFilter() {
    let yupChain = yup.mixed().label(this.label);
    return yupChain;
  }

  forExport() {
    let yupChain = yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        return JSON.stringify(originalValue, null, 2);
      });
    return yupChain;
  }

  forImport() {
    let yupChain = yup.mixed().label(this.label);
    return yupChain;
  }
}
